import * as THREE from 'three'
import { OrbitControls } from 'three/addons/controls/OrbitControls.js'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { PointerLockControls } from 'three/addons/controls/PointerLockControls.js'
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPixelatedPass } from 'three/addons/postprocessing/RenderPixelatedPass.js';
import { UnrealBloomPass } from 'three/addons/postprocessing/UnrealBloomPass.js';

import * as dat from 'dat.gui'

/**
 * Base
 */

let moveForward = false;
let moveBackward = false;
let moveLeft = false;
let moveRight = false;


const objects = [];

let prevTime = performance.now();
const velocity = new THREE.Vector3();
const direction = new THREE.Vector3();
const vertex = new THREE.Vector3();

const audioLoader = new THREE.AudioLoader()
var textureLoader = new THREE.TextureLoader()
var cubeMapLoader = new THREE.CubeTextureLoader()
.setPath( '/cubeMaps/' )
.load( [
    'px.jpeg',
    'nx.jpeg',
    'py.jpeg',
    'ny.jpeg',
    'pz.jpeg',
    'nz.jpeg'
] );




// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Update Materials
 */
const updateAllMaterial = () =>
{
    scene.traverse((child) =>
    {
        if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
            child.material.envMap = cubeMapLoader
        }
        
    })
}

/**
 * Test sphere
 */
const sphere = new THREE.Mesh(
    new THREE.SphereGeometry(0.5, 32, 32),
    new THREE.MeshStandardMaterial({
        metalness: 0.3,
        roughness: 0.4,
    })
)
sphere.castShadow = true
sphere.position.y = 3
scene.add(sphere)

// Floor
const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(2000, 2000, 100, 100 ),
    new THREE.MeshStandardMaterial({
        color: '#777777',
        metalness: 0.3,
        roughness: 0.4
    }))
floor.receiveShadow = true
floor.geometry.setAttribute('uv2', new THREE.Float32BufferAttribute(floor.geometry.attributes.uv.array, 2))
floor.rotateX( - Math.PI / 2 );

scene.add(floor)
//objects.push(floor)

/**
 * Load Model
 */
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')

const manager = new THREE.LoadingManager();
manager.onStart = function ( url, itemsLoaded, itemsTotal ) {
	document.getElementById("myLog").innerHTML = 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' 
};

manager.onLoad = function ( ) {
	document.getElementById("myLog").innerHTML = 'Loading complete!'
};

manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
	document.getElementById("myLog").innerHTML = 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.'
};

manager.onError = function ( url ) {
	document.getElementById("myLog").innerHTML = 'There was an error loading ' + url 
};



const loader = new GLTFLoader(manager);
loader.setDRACOLoader(dracoLoader)

const modelOne = new THREE.Object3D()
const eurica = new THREE.Object3D()
const mercedes = new THREE.Object3D()
const otz = new THREE.Object3D()
const prize = new THREE.Object3D()
const board = new THREE.Object3D()

loader.load('/models/poly4.glb',
    function ( gltf ) {
        gltf.scene.scale.set(2, 2, 2)
        gltf.scene.traverse( function( node ) {

            if ( node.type === 'Mesh' ) { node.castShadow = true; }
    
        } );
        updateAllMaterial()
        modelOne.add( gltf.scene )
        scene.add(modelOne)
        modelOne.name = "modelOne"
        objects.push(modelOne)
    }
)
loader.load('/models/eurica.glb',
    function ( gltf ) {
        gltf.scene.scale.set(2, 2, 2)
        gltf.scene.traverse( function( node ) {

            if ( node.type === 'Mesh' ) { node.castShadow = true
                node.receiveShadow = true }
    
        } );
        gltf.scene.userData.isContainer = true
        updateAllMaterial()
        eurica.add( gltf.scene )
        scene.add(eurica)
        eurica.name = "eurica"
        objects.push(eurica)
    }
)

loader.load('/models/mercedes.glb',
    function ( gltf ) {
        gltf.scene.scale.set(2, 2, 2)
        gltf.scene.traverse( function( node ) {

            if ( node.isMesh ) { node.castShadow = true; }
    
        } );
        updateAllMaterial()
        mercedes.add( gltf.scene )
        scene.add(mercedes)
        mercedes.name = "mercedes"
        objects.push(mercedes)
    }
)

loader.load('/models/otz.glb',
    function ( gltf ) {
        gltf.scene.scale.set(2, 2, 2)
        gltf.scene.traverse( function( node ) {

            if ( node.isMesh ) { node.castShadow = true; }
    
        } );
        updateAllMaterial()
        otz.add( gltf.scene )
        scene.add(otz)
        otz.name = "otz"
        objects.push(otz)
    }
)

loader.load('/models/board.glb',
    function ( gltf ) {
        gltf.scene.scale.set(2, 2, 2)
        gltf.scene.traverse( function( node ) {

            if ( node.isMesh ) { node.castShadow = true; }
    
        } );
        updateAllMaterial()
        board.add( gltf.scene )
        scene.add(board)
        board.name = "board"
        objects.push(board)
    }
)

loader.load('/models/prize.glb',
    function ( gltf ) {
        gltf.scene.scale.set(4, 4, 4)
        gltf.scene.traverse( function( node ) {

            if ( node.isMesh ) { node.castShadow = true; }
    
        } );
        updateAllMaterial()
        prize.add( gltf.scene )
        scene.add(prize)
        prize.name = "prize"
        objects.push(prize)
    }
)

modelOne.position.set(9, 2, 7)
eurica.position.set(20, 2, 0)

board.position.set(10, 2.4, 20)
board.rotateY(180)

mercedes.position.set(-7, 1, -9)
mercedes.rotateY(-180)

otz.position.set(-8, 2, -13)
prize.position.set(-8, 2, 4)
// Load a glTF resource


/**
 * Fog
 */
// Fog
const fog = new THREE.Fog('#262837', 1, 15)
scene.fog = fog

/**
 * Lights
 */
// Ambient light
const ambientLight = new THREE.AmbientLight(0xffffff, 1.2)
scene.add(ambientLight)

// const directionalLight = new THREE.DirectionalLight(0xffffff, 2)
// directionalLight.castShadow = true
// directionalLight.shadow.mapSize.set(1024, 1024)
// directionalLight.shadow.camera.far = 15
// directionalLight.shadow.camera.left = - 7
// directionalLight.shadow.camera.top = 7
// directionalLight.shadow.camera.right = 7
// directionalLight.shadow.camera.bottom = - 7
// directionalLight.position.set(10, 10, 5)
// scene.add(directionalLight)

// const helper = new THREE.DirectionalLightHelper( directionalLight, 5 ); scene.add( helper );

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 1, 100)
camera.position.y = 3;
// camera.position.x = 4
// camera.position.y = 2
// camera.position.z = 5
scene.add(camera)

const ambientSound = new Audio('/sounds/ambient.mp3')

const playHitSound = () =>
{

    ambientSound.volume = 0.6
    ambientSound.currentTime = 0
    ambientSound.loop = true
    ambientSound.play()
}

const stopHitSound = () =>
{
    ambientSound.volume = 0
}

// // Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

// Controls

const controls = new PointerLockControls( camera, document.body );

				const blocker = document.getElementById( 'blocker' );
				const instructions = document.getElementById( 'instructions' );

				instructions.addEventListener( 'click', function () {

					controls.lock();
                    

				} );

				controls.addEventListener( 'lock', function () {

					instructions.style.display = 'none';
					blocker.style.display = 'none';
                    playHitSound()

				} );

				controls.addEventListener( 'unlock', function () {

					blocker.style.display = 'block';
					instructions.style.display = '';
                    stopHitSound()
                    
                    

				} );

				scene.add( controls.getObject() );

				const onKeyDown = function ( event ) {

					switch ( event.code ) {

						case 'ArrowUp':
						case 'KeyW':
							moveForward = true;
							break;

						case 'ArrowLeft':
						case 'KeyA':
							moveLeft = true;
							break;

						case 'ArrowDown':
						case 'KeyS':
							moveBackward = true;
							break;

						case 'ArrowRight':
						case 'KeyD':
							moveRight = true;
							break;

					}

				};

				const onKeyUp = function ( event ) {

					switch ( event.code ) {

						case 'ArrowUp':
						case 'KeyW':
							moveForward = false;
							break;

						case 'ArrowLeft':
						case 'KeyA':
							moveLeft = false;
							break;

						case 'ArrowDown':
						case 'KeyS':
							moveBackward = false;
							break;

						case 'ArrowRight':
						case 'KeyD':
							moveRight = false;
							break;

					}

				};

				document.addEventListener( 'keydown', onKeyDown );
				document.addEventListener( 'keyup', onKeyUp );

				const raycaster = new THREE.Raycaster( new THREE.Vector3(), new THREE.Vector3( 0, - 1, 0 ), 0, 4 );


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor('#262837')
renderer.shadowMap.enabled = true
renderer.useLegacyLights = false

const composer = new EffectComposer( renderer );
const renderPixelatedPass = new RenderPixelatedPass( 7, scene, camera );
renderPixelatedPass.normalEdgeStrength = 0
renderPixelatedPass.depthEdgeStrength = 0
composer.addPass( renderPixelatedPass );



// Debug
const gui = new dat.GUI()
// gui


const params = { pixelSize: 7, normalEdgeStrength: 0, depthEdgeStrength: 0, pixelAlignedPanning: true, threshold: 0,
    strength: 0.3,
    radius: 1,
    exposure: 1 };
gui.add( params, 'pixelSize' ).min( 1 ).max( 16 ).step( 1 )
    .onChange( () => {

        renderPixelatedPass.setPixelSize( params.pixelSize );

    } );
gui.add( renderPixelatedPass, 'normalEdgeStrength' ).min( 0 ).max( 2 ).step( .05 );
gui.add( renderPixelatedPass, 'depthEdgeStrength' ).min( 0 ).max( 1 ).step( .05 );
gui.add( params, 'pixelAlignedPanning' );
const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
				bloomPass.threshold = params.threshold;
				bloomPass.strength = params.strength;
				bloomPass.radius = params.radius;
                
/**
 * Animate
 */
const clock = new THREE.Clock()

var inScope

let INTERSECTED
const CLICKED = [];
const tick = () =>
{
    requestAnimationFrame( tick );

    const time = performance.now();

    if ( controls.isLocked === true ) {

        raycaster.ray.origin.copy( camera.position );
        camera.getWorldDirection( raycaster.ray.direction );
        

        const intersections = raycaster.intersectObjects( objects, true );
        var  selected = intersections[0];
        
        const onObject = intersections.length > 0;
        if(onObject) {
            if ( INTERSECTED != selected.object ) {
                if ( INTERSECTED ) INTERSECTED.material.emissive.setHex( INTERSECTED.currentHex );

						INTERSECTED = intersections[ 0 ].object
						INTERSECTED.currentHex = INTERSECTED.material.emissive.getHex()
						// INTERSECTED.material.emissive.setHex( 0xeeeeee )
                        composer.addPass( bloomPass );

					}

				} else {

					if ( INTERSECTED ) INTERSECTED.material.emissive.setHex( INTERSECTED.currentHex );
                    composer.removePass(bloomPass)

					INTERSECTED = null;

				}
        function addToClicked(clickedObjectName) {
            if(CLICKED.includes(clickedObjectName)) {
                return
            } else {
                CLICKED.push(clickedObjectName)
            }
        }

        document.addEventListener( 'mousedown', onDocumentMouseDown );

        function onDocumentMouseDown( event ) {  
            event.preventDefault();
            if(onObject) {
                if ( INTERSECTED != selected.object ) {
                    if ( INTERSECTED );
    
                            INTERSECTED = intersections[ 0 ].object
                            addToClicked(INTERSECTED.parent.parent.name)
                            document.getElementById("myLog").innerHTML = 'The inventory is: ' + CLICKED
                            console.log(CLICKED);
    
                        }
    
                    } else {
    
                        if ( INTERSECTED ) ;
                        
    
                        INTERSECTED = null;
    
                    }
    
        }


        const delta = ( time - prevTime ) / 5000;

        velocity.x -= velocity.x * 10.0 * delta;
        velocity.z -= velocity.z * 10.0 * delta;

        velocity.y -= 9.8 * 100.0 * delta; // 100.0 = mass

        direction.z = Number( moveForward ) - Number( moveBackward );
        direction.x = Number( moveRight ) - Number( moveLeft );
        direction.normalize(); // this ensures consistent movements in all directions

        if ( moveForward || moveBackward ) velocity.z -= direction.z * 400.0 * delta;
        if ( moveLeft || moveRight ) velocity.x -= direction.x * 400.0 * delta;

        if ( onObject === true ) {

            velocity.z = Math.max( 0, velocity.z );

        }

        controls.moveRight( - velocity.x * delta );
        controls.moveForward( - velocity.z * delta );

        // controls.getObject().position.y += ( velocity.y * delta ); // new behavior

        // if ( controls.getObject().position.y < 2 ) {

        //     velocity.y = 0;
        //     controls.getObject().position.y = 2;

        // }
      
        console.log(onObject);
    
    }

    prevTime = time;

    //renderer.render( scene, camera );
    composer.render();
}

tick()